@tailwind base;
@tailwind components;
@tailwind utilities;

/* @font-face {
  font-family: 'SF Pro';
  src: url('./assets/fonts/SF-Pro.ttf') format('truetype');
} */
/*
@font-face {
  font-family: 'SF Pro';
  src: url('./assets/fonts/SfProSemiBold.otf') format('opentype');
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'SF Pro';
  src: url('./assets/fonts/SfProBold.otf') format('opentype');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'SF Pro';
  src: url('./assets/fonts/SfProThin.otf') format('opentype');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'SF Pro';
  src: url('./assets/fonts/SfProItalic.otf') format('opentype');
  font-style: italic;
  font-weight: normal;
} */

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
